import React from 'react';
import "./ContactUs.css"
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup'

export default function ContactUs() {
  return (
      <Container className={"contact-us-section d-flex flex-column"}
                 id={"contact-us"} fluid>
        <Row sm={12} xs={12} md={12}>
          <Col sm={12} xs={12} md={{span: 12}} className={'justify-content-center'}>
            <p style={{fontSize: 50}}>Can't resist <strong>connecting?</strong></p>
          </Col>
          <Col sm={12} xs={12} md={{span: 12}} className={'justify-content-center'}>
              Neither can we! Write to us.
          </Col>
          <Col sm={12} xs={12} md={{span: 5, offset: 1}}
               className={'form justify-content-center mt-5'}>
            <Form>
              <InputGroup className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Name*"
                    aria-label="Name"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                    type="email"
                    placeholder="Email*"
                    aria-label="Email"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Contact Number"
                    aria-label="Name"
                />
              </InputGroup>
            </Form>
          </Col>
          <Col sm={12} xs={12} md={5} className={'justify-content-center mt-5'}>
            <Form>
              <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Subject"
                    aria-label="Subject"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                    className={'text-area'}
                    placeholder="Tell us how we can help you :)"
                    as="textarea"
                    aria-label="With textarea"
                    rows="3"/>
              </InputGroup>
            </Form>
          </Col>
          <Col xs={12} md={12} className={'justify-content-center pb-3'}>
            <Button variant="light">Send Message</Button>
          </Col>
        </Row>
      </Container>

  )
}
