export const featuresInfo = [
  {
    name: "Dashboard",
    description:
        "Track strategic alignment through leadership & individual dashboards",
  },
  {
    name: "Feedback",
    description:
        "Measure performance & growth via self, peer & project feedback",
  },
  {
    name: "Goals",
    description:
        "Set, manage, track strategic & tactical goals by objectives, as OKRs",
  },
  {
    name: "Learning",
    description:
        "Define & link learning to enterprise goals, and align to market needs",
  },
  {
    name: "Administration",
    description:
        "Set-up enterprise, onboard people, define roles & rights, get started",
  },
];

export const gifs = [
  '/img/riga_thumb.png',
  '/img/riga_thumb.png',
  '/img/riga_thumb.png',
  '/img/riga_thumb.png',
  '/img/riga_thumb.png',
]



