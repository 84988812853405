import React from "react";
import {Card, Button} from "react-bootstrap";
import Slider from "react-slick";
import teamInfo from "./team.info";
import "./TeamSection.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TeamSection() {
  const greyampURL = "https://www.greyamp.com/";
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    // autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    pauseOnDotsHover: true,
    border: "none",
    dotsClass: "slick-dots show-dots",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gotoURL = (url) => {
    console.log("lol", url);
    window.open(url, "_blank");
  };
  const content = teamInfo.map(
      ({name, description, profilePicUrlRegular, linkedInUrl}, index) => (

          <Col xs={11}>
            <Card key={index} xs={12} className={"box single-team"}>
              <Card.Img
                  src={profilePicUrlRegular}
                  className={"team-member-photo text-center mx-auto"}
              />
              <Card.Body>
                <Card.Title className={"team-name"}>
                  <h3>{name}</h3>
                </Card.Title>
                <Card.Text className={"card-content"}>{description}</Card.Text>
                <img
                    style={{height: "20px"}}
                    src="/img/Linkedin_icon.svg"
                    className={"on-hover icon-position mx-auto"}
                    alt="linkedIn"
                    onClick={() => gotoURL(linkedInUrl)}
                ></img>
              </Card.Body>
            </Card>
          </Col>
      )
  );

  return (
      <Container id="team" className={"team-section"} fluid>
        <Row>
          <Col xs={12} className={'d-flex justify-content-center'
          + ' team-section-header'
          + ' mx-auto'}>
            Meet the <strong>Team</strong>
          </Col>
          <Col xs={12} className={'d-flex justify-content-center'
          + ' team-section-text__responsive'
          + ' mx-auto'}>
            The artists who've nailed the science behind transformation
            management
          </Col>
        </Row>
        <Slider className={'slider-team mx-auto my-3 py-2'} {...settings}>
          {content}
        </Slider>

        <Row>
          <Col className={'d-flex justify-content-center team-section-header'
          + ' mx-auto my-3'}>
            <Button
                variant="outline-secondary"
                onClick={() => gotoURL(greyampURL)}>
              Get to know us better...
            </Button>
          </Col>
        </Row>
      </Container>
  );
}

export default TeamSection;
