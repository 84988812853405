import React from 'react';
import "./Header.css"
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

export default function Header() {
    return (
        <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark"
                variant="dark">
          <Navbar.Brand href="#landing-section"><img
              src={'/img/gale_logo.svg'} alt={"Greyamp Logo"}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={'ml-auto'}>
              <Nav.Link className={'nav-link-item'} href="#why-gale">WHY GALE?</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#features">FEATURES</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#team">TEAM</Nav.Link>
              <Nav.Link className={'nav-link-item'} href="#contact-us">CONTACT US</Nav.Link>
            </Nav>
            <Button variant="outline-primary" href="#contact-us">SIGN-UP</Button>
          </Navbar.Collapse>
        </Navbar>
    )
}
