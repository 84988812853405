const teamInfo = [
  {
    name: "Preethi",
    designation: "CEO",
    description:
    "An Agile enthusiast having had experience working with organisations & teams grappling with digital transformation initiatives",
    linkedInUrl: "https://www.linkedin.com/in/preethimadhu/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Preethi_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Preethi_Regular.svg",
  },
  {
    name: "Madhu",
    designation: "CTO",
    description:
    "Hands-on sales leader & serial entrepreneur with diverse experience scaling businesses in various geographies across the globe.",
    linkedInUrl: "https://www.linkedin.com/in/madhu-lakshmanan-12b5ba4/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Madhu_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Madhu_Regular.svg",
  },
  {
    name: "Ishank",
    designation: "COO",
    description:
      "Experienced Business Growth consultant having worked with Sales, Marketing and Business Strategy across 5+ sectors",
    linkedInUrl: "https://www.linkedin.com/in/ishank-bansal-91b4a6ba/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Ishank_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Ishank_Regular.svg",
  },
  {
    name: "Anna",
    designation: "Senoir Software Engineer",
    description:
    "A Talent Enthusiast, and continuous Learner, who has completed her specialization in Human Resources.",
    linkedInUrl: "https://www.linkedin.com/in/annapauly/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Anna_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Anna_Regular.svg",
  },
  {
    name: "Santosh",
    designation: "Senoir Software Engineer",
    description:
    "A fullstack developer with an affinity for backend development",
    linkedInUrl: "https://www.linkedin.com/in/santoshbachar/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Santosh_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Santosh_Regular.svg",
  },
  {
    name: "Rajat",
    designation: "ISO Devloper",
    description:
    "Experienced Developer with strong grasp in building progressive Web apps, Front end design, UX and API designs",
    linkedInUrl: "https://www.linkedin.com/in/7rajatgupta/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Rajat_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Rajat_Regular.svg",
  },
  {
    name: "Nidhi",
    designation: "Android Devloper",
    description:
    "Result-oriented Fullstack developer with a knack for conceptualising and delivering elegant, user-friendly solutions.",
    linkedInUrl: "https://www.linkedin.com/in/nidhi-mittal-6b576b12a/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Nidhi_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Nidhi_Regular.svg",
  },
  {
    name: "Rishikesh",
    designation: "Web Devloper",
    description:
    "Consulting professional with experience in data reporting strategy, focusing on Digital Transformation and brand presence",
    linkedInUrl: "https://www.linkedin.com/in/kulrari/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Rishikesh_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Rishikesh_Regular.svg",
  },
  {
    name: "Ananth",
    designation: "Web Devloper",
    description:
    "Experienced Growth Strategy Consultant having worked with GTM, Sales Strategy, Sales Enablement, and Business Development.",
    linkedInUrl: "https://www.linkedin.com/in/ananthnaghn/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Ananth_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Ananth_Regular.svg",
  },
  {
    name: "Aayushi",
    designation: "Web Devloper",
    description:
    "A driven consulting professional with experience in brand strategy and data analysis",
    linkedInUrl: "https://www.linkedin.com/in/aayushipaliwal/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Aayushi_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Aayushi_Regular.svg",
  },
  {
    name: "Kshitiz",
    designation: "Web Devloper",
    description:
    "A programming enthusiast with a focus on Client Enablement and Digital Transformation",
    linkedInUrl: "https://www.linkedin.com/in/kshitiz-/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Kshitiz_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Kshitiz_Regular.svg",
  },
  {
    name: "Naveen S",
    designation: "Web Devloper",
    description:
    "A Tech-enthusiast, and experienced DevOps Consultant with hands-on automation expertise",
    linkedInUrl: "https://www.linkedin.com/in/naveenshankareswaran/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Naveen_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Naveen_Regular.svg",
  },
  {
    name: "Paridhi",
    designation: "Web Devloper",
    description:
    "A talent enthusiast with experience in People aspects, Organisation Development, O&C Transformation and Corporate Strategy.",
    linkedInUrl: "https://www.linkedin.com/in/paridhikgupta/",
    profilePicUrlHovered: "https://storage.googleapis.com/gale_website/Paridhi_Hovered.svg",
    profilePicUrlRegular: "https://storage.googleapis.com/gale_website/Paridhi_Regular.svg",
  },
  {
    name: "Arvindh",
    designation: "Web Devloper",
    description:
    "An enthusiastic professional experienced in Consulting & Market Research having worked across Sales and Marketing strategy",
    linkedInUrl: "https://www.linkedin.com/in/arvindh-kumar-6999a138/",
    profilePicUrl: "https://via.placeholder.com/125",
  },
  {
    name: "Ferdi",
    designation: "Web Devloper",
    description:
    "Digital practices with varied experience across product management, digital transformation and strategic organizations.",
    linkedInUrl: "https://www.linkedin.com/in/ferdinand-rinaldy-7611144a/",
    profilePicUrl: "https://via.placeholder.com/125",
  },
  {
    name: "Soumya",
    designation: "Web Devloper",
    description:
    "A people’s person who takes care of the day-to-day HR and administrative activities within the organisation.",
    linkedInUrl: "https://www.linkedin.com/in/soumya-s-9543b9155/",
    profilePicUrl: "https://via.placeholder.com/125",
  },
];
export default teamInfo;
