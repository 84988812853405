import React from "react";
import {Button, Image, Container, Row, Col} from "react-bootstrap";
import "./LandingSection.css";

function LandingSection() {
  return (
      <Container className={"landing-section d-flex align-items-center"}
                 id={"landing-section"} fluid>
        <Row md={12}>
          <Col md={{span: 5, offset: 1, order:1}} sm={{span: 10, offset: 1, order:2}}
               xs={{span: 10, offset: 1, order:2}} className={'landing-section-content'}>
            <Row>
              <Col md={12}>
                <p className={"landing-section-header"}>Create change <strong><br/>
                    the right way</strong></p>
              </Col>
              <Col md={12}>
                <p className={"landing-section-text"
                + " landing-section-text__responsive"}>
                  Go Digital with our Unique Transformation Management Platform.<br/>
                  Triangulate Market Needs and Drive Value that Matters.</p>
              </Col>
              <Col className={'pt-2'}>
                <Button style={{marginLeft: 0}} variant="light"
                        href="#contact-us"><p
                    className={'landing-section-text__responsive mb-0'}>Book a demo</p></Button>
                <Button style={{marginLeft: 0}} variant="outline-primary"><p
                    className={'landing-section-text__responsive mb-0'}>Get to
                  know more</p></Button>
              </Col>
            </Row>
          </Col>
          <Col md={{span: 6, order: 2}} sm={{span:12, order:1}} xs={{span:12, order:1}}
               className={'landing-section-image my-4 py-4'}>
            <Image className={'fill img-responsive'} src="/img/landing_section_image.svg"
                   fluid/>
          </Col>
        </Row>
      </Container>
  );
}

export default LandingSection;
