import React from 'react';
import "./WhyGaleSection.css"
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function WhyGaleSection() {
  return (
      <Container className={"why-gale-section d-flex align-items-center"}
                 id={"why-gale"} fluid>
        <Row md={12}>
          <Col md={{span: 6}} sm={12} xs={12}
               className={'why-gale-image my-4 py-4'}>
            <Image className={'fill'} src="/img/why_gale_image.svg" fluid/>
          </Col>
          <Col xl={{span: 5, offset: 0}} lg={{span: 5, offset: 0}}
               md={{span: 5, offset: 0}} sm={{span: 10, offset: 1}}
               xs={{span: 10, offset: 1}}
               className={'why-gale-section-content'}>
            <Row>
              <Col md={12}>
                <p className={'why-gale-section-header'}>Only <strong>3/10</strong> Transformation Efforts <strong>Succeed.</strong>
                </p>
              </Col>
              <Col md={12}>
                <p className={'why-gale-section-text why-gale-section-text__responsive'}
                   style={{textAlign: "left", paddingLeft:0}}>
                  Others fail due to lack of: Alignment, Visibility, Competencies
                </p>
              </Col>
              <Col md={12}>
                <p className={'why-gale-section-header'}>Gale <strong>Enables</strong>
                </p>
              </Col>

              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Enterprise-wide Strategic Alignment</p>
              </Col>
              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Progress of Delivery Outcomes Visible</p>
              </Col>
              <Col md={12}>
                <Image className={'icon'} style={{marginBottom: '1rem'}}
                       src={"/img/Book_icon.svg"}/>
                <p className={'why-gale-section-text'
                + ' why-gale-section-text__responsive'}>Competency building in convergence to goals</p>
              </Col>
              {/*<Col md={12}>*/}
              {/*  <Image className={'icon'} style={{marginBottom: '1rem'}}*/}
              {/*         src={"/img/Book_icon.svg"}/>*/}
              {/*  <p className={'why-gale-section-text why-gale-section-text__responsive'}>Engage*/}
              {/*    to be networked</p>*/}
              {/*</Col>*/}
              {/*<Col md={12}>*/}
              {/*  <p className={'why-gale-section-text why-gale-section-text__responsive'}*/}
              {/*     style={{textAlign: "left"}}>*/}
              {/*    ...and triangulate the change they see*/}
              {/*  </p>*/}
              {/*</Col>*/}
            </Row>
          </Col>
        </Row>
      </Container>
  )
}
