import React from 'react';
import "./Footer.css"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export default function Footer() {
  return (
      <Container className={"footer-section align-items-center"} fluid>

        <Row md={12} xs={12} sm={12} className={'pt-4'}>

          <Col sm={{span:'auto'}} xs={'auto'} md={{span:'auto', order:1}} className={'pt-3'
          + ' gale-logo'}>
            <Image src={'/img/gale_logo.svg'} className={'gale-logo-big'} fluid/>
          </Col>

          <Col xs={{span:"auto"}} sm={{span:'auto'}} className={'d-flex'
          + ' justify-content-right social-media-icons'
          + ' pt-3'} md={{span:'auto', order:3}}>
            <a href={'https://www.linkedin.com/company/greyamp-consulting/?originalSubdomain=in'} target="_blank" rel="noopener noreferrer" >
              <Image src={'/img/linkedin_icon_fill.svg'} style={{paddingRight:'1rem'}}/>
            </a>
            <a href={'https://www.instagram.com/greyamp_consulting/'} target="_blank" rel="noopener noreferrer" >
            <Image src={'/img/instagram_icon_fill.svg'} />
            </a>
          </Col>

          <Col className={'d-flex justify-content-center pt-3 address'} xs={{span: 12}} sm={{span:12}}  md={{span:'auto', offset:2, order:2}}>
            4th Floor, #14, Sector 6, HSR Layout<br/>
              Bengaluru, Karnataka 560068, India
          </Col>

        </Row>

        <Row md={12} >
          <Col className={'copyright pt-3 d-flex justify-content-center'} md={{span:'auto', order:4}} xs={12} sm={{span:12}} style={{color:'rgba(255, 255, 255,'
                + ' .5)'}}>
            © Copyrights 2020 Greyamp | All rights reserved
          </Col>
        </Row>

      </Container>
  )
}
