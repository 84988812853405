import React, {Component} from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import {featuresInfo, gifs} from './features.info';
import './FeaturesSection.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export default class FeaturesSection extends Component {
  state = {
    activeSlide: 0
  }

  render() {
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      dots: true,
      initialSlide:-1,
      afterChange: current => this.setState({activeSlide: current}),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

    };

    const content = featuresInfo.map(
        ({name, description}) => (
            <Col xs={10}>
              <Card style={{height: '100%'}}>
                <Card.Body>
                  <Card.Title style={{textAlign: 'left'}}>{name}</Card.Title>
                  <Card.Text
                      style={{textAlign: 'left'}}>{description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
        )
    )

    return (

        <Container className={'features-section my-5'} id={'features'} fluid>
          <Slider className={'slider-features mx-auto'} {...settings}>
            {content}
          </Slider>
          <Row>
            <Col className={'mx-auto'} xs={6}>
              <Image className={'pt-3 mt-3'} src={gifs[this.state.activeSlide]}
                     fluid/>
            </Col>
          </Row>
        </Container>
    );
  }
}
