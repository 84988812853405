import React, {Component} from 'react';
import './App.css';
import Header from './components/HeaderSection/Header'
import LandingSection from './components/LandingSection/LandingSection.js'
import WhyGaleSection from "./components/WhyGaleSection/WhyGaleSection";
import TeamSection from './components/TeamSection/TeamSection';
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./components/FooterSection/Footer";
import FeaturesSection from "./components/FeaturesSection/FeaturesSection";

class App extends Component {
  render() {
    return (
        <>
          <Header style={{zIndex:1}}/>
          <LandingSection style={{zIndex:1}}/>
          <WhyGaleSection style={{zIndex:1}}/>
          <FeaturesSection style={{zIndex:1}}/>
          {/*<img className={'features-image fill'} src={'img/features_background.svg'} fluid/>*/}
          <TeamSection style={{zIndex:1}}/>
          <ContactUs style={{zIndex:1}}/>
          <Footer style={{zIndex:1}}/>
        </>
    );
  }
}

export default App;
